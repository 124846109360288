<template>
  <div class="footer">
    <h2>
      Tom Mrt - 2023
    </h2>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped lang="scss">

.footer {
  padding: 20px 0;

  h2 {
    color: #fafafa;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
  }
}

</style>
